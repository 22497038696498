.startBarLeft {
    position: fixed;
    bottom: 0;
    z-index: 50;
    /* width: 308px; */
    height: 54px;
    /* border-top: 2px solid #000000; */
}

.startBarRight {
    position: fixed;
    bottom: 0;
    z-index: 50;
    /* width: 208px; */
    height: 54px;
}

.startBarBG {
    background-color: #C0C0C0;
    width: 100%;
    height: 54px;
    position: fixed;
    bottom: 0;
    border-top: 2px solid #ffffff;
}

.clock-text {
    margin: 9.5px 13px 0px 0px;
    font-family: "W95FA";
    color: #000000;
    font-size: 25px;
    z-index: 100;
}